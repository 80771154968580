import axios from 'axios';
import { apiAxios as authAxios } from '../axios.config';

const BASE_PATH = '/api/competitions';

export const getCompetitions = (platformCode) => {
  return axios.get(`api/platform/${platformCode}/competitions/`);
};

export const getCompetitionHabitations = (competitionCode, platformCode,index,size) => {
  return index=== undefined ? authAxios.get(
    `api/platform/${platformCode}/competitions/${competitionCode}/habitations`
  ) : authAxios.get(
      `api/platform/${platformCode}/competitions/${competitionCode}/habitations`,{ params: { index: index, size: size }}
  );
};

export const getCompetitionByCompetitionCode = (
  competitionCode,
  platformCode
) => {
  return authAxios.get(
    `api/platform/${platformCode}/competitions/competitionCode/${competitionCode}`
  );
};

export const getDistrict = () => {
  return axios.get(`/api/administrativeDivisions/1`);
};

export const getCounties = () => {
  return axios.get(`/api/administrativeDivisions/2`);
};

export const getDistrictsByEAACandidatures = () => {
  return axios.get(`/api/administrativeDivisions/EAA/candidatures/1`);
};

export const getCountiesByEAACandidatures = (districtId) => {
  return axios.get(`/api/administrativeDivisions/EAA/candidatures/2/${districtId}`);
};

export const getDistrictEAA = (competitionCode) => {
  return axios.get(`/api/administrativeDivisions/EAA/${competitionCode}/1`);
};

export const getDistrictWithCompetitions = (platformCode, state) => {
  return axios.get(
    `${BASE_PATH}/${platformCode}/divisions/1${
      typeof state === 'boolean' ? `?state${state}` : ''
    }`
  );
};

export const getCounty = (district) => {
  return axios.get(`/api/administrativeDivisions/2/${district}`);
};

export const getCountyEAA = (district, competitionCode) => {
  return axios.get(
    `/api/administrativeDivisions/EAA/${competitionCode}/2/${district}`
  );
};

export const getParish = (county) => {
  return axios.get(`/api/administrativeDivisions/3/${county}`);
};

export const getParishEAA = (county, competitionCode) => {
  return axios.get(
    `/api/administrativeDivisions/EAA/${competitionCode}/3/${county}`
  );
};

export const getCountyWithCompetitions = (platformCode, district, state) => {
  return axios.get(
    `${BASE_PATH}/${platformCode}/divisions/2/${district}${
      typeof state === 'boolean' ? `?state${state}` : ''
    }`
  );
};

export const getParishWithCompetitions = (platformCode, county, state) => {
  return axios.get(
    `${BASE_PATH}/${platformCode}/divisions/3/${county}${
      typeof state === 'boolean' ? `?state${state}` : ''
    }`
  );
};

export const updateCompetition = (code, platformCode, body) => {
  return authAxios.put(
    `api/platform/${platformCode}/competitions/${code}`,
    body
  );
};

export const createCompetition = (body) => {
  return authAxios.post(BASE_PATH, body);
};


export const getHabitationFromPAA = (
  competitionCode,
  platformCode,
  nif,
  certificate
) => {
  return authAxios.get(
    `api/platform/${platformCode}/competitions/${competitionCode}/habitation/${certificate
      .replaceAll('/', '')
      .replace('%2F', '')}/${nif}`
  );
};
export const addHabitationToCompetition = (
  competitionCode,
  platformCode,
  habitation
) => {
  return authAxios.post(
    `api/platform/${platformCode}/competitions/${competitionCode}/habitation`,
    habitation
  );
};


export const changeYoutubeUrlToCompetition = (
    competitionCode,
    platformCode,
    youtubeUrl
) => {
  return authAxios.post(
      `api/platform/${platformCode}/competitions/${competitionCode}/addYoutubeUrl`,
      youtubeUrl
  );
};



export const addHabitationToCompetitionEAA = (competitionCode, codes) => {
  return authAxios.post(
    `api/platform/EAA/competitions/${competitionCode}/habitations`,
    codes
  );
};

export const searchHabitationToCompetitionEAA = (
  competitionCode,
  habitation
) => {
  return authAxios.get(
    `api/platform/EAA/competitions/${competitionCode}/habitations/search`,
    {
      params: {
        districtCode: habitation.habitationAddress.districtCode,
        countyCode: habitation.habitationAddress.countyCode,
        parishCode: habitation.habitationAddress.parishCode,
        typology: habitation.typology.code,
        singleFamilyHouse: habitation.singleFamilyHouse,
      },
    }
  );
};

export const getAllowActions = (competitionCode, platformCode) => {
  return axios.get(
    `api/platform/${platformCode}/competitions/${competitionCode}/allowActions`
  );
};

export const changeCompetitionPartial = (
  competitionCode,
  platformCode,
  fields
) => {
  return authAxios.patch(
    `api/platform/${platformCode}/competitions/${competitionCode}`,
    fields
  );
};

export const getCompetitionsWithFilter = (
  platformCode,
  district,
  county,
  typology,
  maxRent,
  active,
  minRent
) => {
  return authAxios.get(`api/platform/${platformCode}/competitions`, {
    params: {
      district,
      county,
      typology,
      maxRent,
      minRent,
      state: active,
    },
  });
};
/**
 * @param  {string} competitionCode code to validate
 * @returns  {Promise<AxiosResponse<boolean>>}`
 */
export const validateCompetitionCode = (competitionCode, platformCode) => {
  return authAxios.get(
    `api/platform/${platformCode}/competitions/validateCode/${competitionCode}`
  );
};
