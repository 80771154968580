import dateFormat from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import {
  FaCheck,
  FaEye,
  FaInfoCircle,
  FaPencilAlt,
  FaRegFileAlt,
  FaRegQuestionCircle,
} from 'react-icons/fa';
import { TiPlus } from 'react-icons/ti';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import {
  hasPermission,
  isCandidate,
  isIhruUser,
} from '../../../authentication/authenticationHelper';
import { AlertError } from '../../../components/bootstrap/AlertError';
import DrawDialog from '../../../components/draw/DrawDialog';
import { HabitationCard } from '../../../components/habitation/HabitationCard';
import HowItWorksDialog from '../../../components/HowItWorksDialog';
import Loading from '../../../components/Loading';
import { SubTemplate } from '../../../components/SubTemplate';
import { ConfigObject } from '../../../config';
import { useGlobalContext } from '../../../context';
import '../../../css/custom.css';
import {
  APPROVED,
  CANDIDATURES_SUBMISSION_PHASE,
  CLOSED,
  CompetitionState,
  DRAFT,
  DRAW,
  DRAWN_CANDIDATURES_TREATMENT_PHASE,
  PRE_PUBLICATION_ACCEPTED_CANDIDATURES, PUBLICATION_ACCEPTED_CANDIDATURES,
  PUBLISHED,
} from '../../../models/CompetitionState';
import { DocumentTypeCode } from '../../../models/DocumentTypeCode';
import { PlatformType } from '../../../models/PlatformType';
import { getCandidature } from '../../../rest/candidatureuser';
import {
  changeCompetitionPartial, changeYoutubeUrlToCompetition,
  getAllowActions,
  getCompetitionHabitations,
} from '../../../rest/competition';
import { startDraw } from '../../../rest/draw';
import { getPlatform } from '../../../rest/platform';
import { handleError, isBusinessError } from '../../../utils/handleError';
import InfiniteScroll from 'react-infinite-scroll-component';
import {YoutubeUrlDialog} from "../../../components/competition/YoutubeUrlDialog";

function CompetitionHabitations() {
  const { competitionCode } = useParams();
  const { platformCode } = useParams();
  const { accessInfo } = useGlobalContext();
  const { nphCodeUrl } = ConfigObject.get();

  const [competitionHabitations, setCompetitionHabitations] = useState(null);
  const [candidature, setCandidature] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [existCandidature, setExistCandidature] = useState(false);
  const [allowActions, setAllowActions] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [showYoutubeUrl, setShowYoutubeUrl] = useState(false);
  const [drawStart, setDrawStart] = useState(false);
  const [showModalDraw, setShowModalDraw] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [indexHabitation, setIndexHabitation] = useState(1);

  const handleClose = () => setShow(false);
  const handleCloseYoutubeUrl = () => setShowYoutubeUrl(false);
  const handleCloseDrawModal = () => setShowModalDraw(false);
  const handleShow = () => setShow(true);
  const handleShowYoutube = () => setShowYoutubeUrl(true);

  const intl = useIntl();
  const history = useHistory();

  const pattern = ConfigObject.get().DATE_TIME_PATTERN;

  useEffect(() => {
    async function fetchData() {
      try {
        const [
          competitionHabitationsResponse,
          platformResponse,
          allowActionsResponse,
        ] = await Promise.all([
          getCompetitionHabitations(competitionCode, platformCode,0,15),
          getPlatform(platformCode),
          getAllowActions(competitionCode, platformCode),
        ]);

        setCompetitionHabitations(competitionHabitationsResponse.data);
        setPlatform(platformResponse.data);
        setAllowActions(allowActionsResponse.data);

        if (isCandidate(accessInfo)) {
          const { data: candidatures } = await getCandidature(
            competitionCode,
            platformCode
          );
          setCandidature(candidatures);
          setExistCandidature(true);
        }
        setLoading(false);
      } catch (error) {
        if (isCandidate(accessInfo) && error.response?.status === 400) {
          setExistCandidature(false);
        } else {
          setError(error);
        }
        console.log(error);
        setLoading(false);
      }
    }

    fetchData();
  }, []);


  const fetchMoreData = async () => {
    try {

      const { data: moreHabitationsResponse } = await  getCompetitionHabitations(competitionCode, platformCode,indexHabitation,15);
      competitionHabitations.habitations.push(...moreHabitationsResponse.habitations)
      setCompetitionHabitations({...competitionHabitations});
      moreHabitationsResponse.habitations.length > 0 ? setHasMore(true) : setHasMore(false);
      setIndexHabitation((prevIndex) => prevIndex + 1);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  const handleStateChange = async (e, state) => {
    setLoading(true);

    e.preventDefault();

    const body = [{ fieldName: 'state', value: state }];

    try {
      await changeCompetitionPartial(competitionCode, platformCode, body);
      const { data: allowActionsData } = await getAllowActions(
        competitionCode,
        platformCode
      );
      competitionHabitations.state = state;
      setAllowActions(allowActionsData);
      setCompetitionHabitations({ ...competitionHabitations });
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const handleStartDraw = async (cadence, setSubmitting, setErrors) => {
    setLoading(true);
    try {
      await startDraw(competitionCode, platformCode, cadence);
      setLoading(false);
      setDrawStart(true);
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setShowModalDraw(false);
      setSubmitting(false);
    }
  };



  const handleSetYoutubeUrl = async (url, setSubmitting) => {
    setLoading(true);
    try {
      await changeYoutubeUrlToCompetition(
          competitionCode,
          platformCode,
          {youtubeUrl :url}
      );
      competitionHabitations.youtubeUrl = url;


    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
      setShowYoutubeUrl(false);
      setSubmitting(false);
    }
  };



  if (loading) {
    return <Loading />;
  }

  if (drawStart) {
    history.replace(
      `/programas/${platformCode}/concursos/${encodeURIComponent(
        competitionHabitations.code
      )}/sorteio`
    );
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate
      hasBackButton
      title={`${intl.formatMessage({
        id: 'competitionHabitation.competition',
      })} ${competitionHabitations.code} - ${intl.formatMessage({
        id: `competitionState.${competitionHabitations.state}`,
      })}`}
    >
      <div className={'container'}>

        <AlertError error={error} />
        <div className={'row'}>
          <div className={'col-md-9 mb-5'}>
            <p className={'text-secondary'}>
              <FormattedMessage id='competitionHabitation.datesStartEntText' />
              <strong>{competitionHabitations.startRegistrationDate}</strong>
              <FormattedMessage id='all.toText' />
              <strong> {competitionHabitations.endRegistrationDate}</strong>
            </p>
            {competitionHabitations.habitations.length > 0 ? (
              <div>
                <hr className={'my-4'} />
                <h6 className={'text-uppercase mb-4'}>
                  {competitionHabitations.totalHabitations}
                   <FormattedMessage id={competitionHabitations.totalHabitations === 1 ? 'competitionHabitation.1habitationInCompetitionsText' : 'competitionHabitation.habitationInCompetitionsText'}  />
                </h6>
                  <InfiniteScroll
                      dataLength={competitionHabitations.habitations.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      className={'row'}
                  >
                        {competitionHabitations.habitations &&
                        competitionHabitations.habitations.map((habitation) => (
                            <HabitationCard
                                key={habitation.externalId}
                                habitation={habitation}
                                toShowImage={CompetitionState.getBeforeAdmission().includes(
                                    competitionHabitations.state
                                ) }
                            />
                        ))}
                  </InfiniteScroll>
              </div>
            ) : (
              <div className='empty-state my-4'>
                <FaInfoCircle />
                <p>
                  <FormattedMessage id='competitionHabitation.addHabitationCTA' />
                  <br />
                  <strong>
                    <FormattedMessage id='annunciations.competition' />{' '}
                    {competitionHabitations.code}
                  </strong>
                </p>
              </div>
            )}
          </div>
          <div className={'col-md-3 side-menu'}>
            <div className='mx-2 mb-4'>
              {hasPermission('CHANGE_COMPETITION', accessInfo) &&
                allowActions.states.length > 0 && (
                  <div className='border-bottom mb-3'>
                    {allowActions.states.map((state, index) => (
                      <button
                        key={index}
                        className={
                          'btn btn-outline-primary mb-3 text-uppercase'
                        }
                        onClick={(e) => handleStateChange(e, state)}
                      >
                        <FormattedMessage
                          id={`competitionState.changeTo${state}`}
                        />
                      </button>
                    ))}

                    {(competitionHabitations.state === PUBLICATION_ACCEPTED_CANDIDATURES || competitionHabitations.state ===
                        DRAW) &&
                    (
                        isIhruUser(accessInfo) &&
                        hasPermission('START_DRAW', accessInfo) && (
                            <button
                                className={
                                  'btn btn-outline-primary mb-3 text-uppercase'
                                }
                                onClick={handleShowYoutube}
                            >
                              <FormattedMessage id='competition.youtubeUrl.button'/>
                            </button>
                        ))}

                    {hasPermission('START_DRAW', accessInfo) &&
                      competitionHabitations.state ===
                        'PUBLICATION_ACCEPTED_CANDIDATURES' &&
                      competitionHabitations.drawDate <
                        dateFormat(new Date(), pattern) &&
                      allowActions.canInitDraw && (
                        <button
                          className={
                            'btn btn-outline-primary mb-3 text-uppercase'
                          }
                          onClick={() => setShowModalDraw(true)}
                        >
                          <FormattedMessage
                            id={`competitionState.changeToDRAW`}
                          />
                        </button>
                      )}
                  </div>
                )}
              {hasPermission('ADD_HABITATION', accessInfo) &&
                competitionHabitations.state === DRAFT && (
                  <Link
                    to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                      competitionHabitations.code
                    )}/alojamentos/pre/adicionar`}
                    className={'btn btn-outline-primary mb-3'}
                  >
                    <TiPlus />
                    <FormattedMessage id='addHabitation.insertText' />
                  </Link>
                )}
              {hasPermission('EDIT_COMPETITION', accessInfo) &&
                competitionHabitations.state === DRAFT && (
                  <Link
                    to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                      competitionHabitations.code
                    )}/editar`}
                    className={'btn btn-outline-primary mb-3'}
                  >
                    <FaPencilAlt />
                    <FormattedMessage id='competitions.editCompetitionText' />
                  </Link>
                )}
              {hasPermission('CHANGE_COMPETITION', accessInfo) &&
                competitionHabitations.state !== DRAFT &&
                allowActions.datesCanBeChanged.length > 0 && (
                  <Link
                    to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                      competitionHabitations.code
                    )}/datas/editar`}
                    className={'btn btn-outline-primary mb-3'}
                  >
                    <FaPencilAlt />
                    <FormattedMessage id='competitionDates.editText' />
                  </Link>
                )}
              {hasPermission('ADD_COMPETITION_DOCUMENT', accessInfo) &&
                (competitionHabitations.state === DRAFT ||
                  CompetitionState.getActives().includes(
                    competitionHabitations.state
                  )) && (
                  <Link
                    to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                      competitionHabitations.code
                    )}/documentos/editar`}
                    className={'btn btn-outline-primary mb-3'}
                  >
                    <FaRegFileAlt />
                    <FormattedMessage id='competitionForm.addDocuments' />
                  </Link>
                )}
              {hasPermission('CREATE_CANDIDATURE', accessInfo) &&
                competitionHabitations.state ===
                  CANDIDATURES_SUBMISSION_PHASE &&
                platformCode !== PlatformType.EAA &&
                isCandidate(accessInfo) &&
                !existCandidature && (
                  <div className='mb-4'>
                    {competitionHabitations.habitations.length !== 0 && (
                      <Link
                        to={`/programas/${platformCode}/concursos/${competitionCode}/candidatar`}
                        className={'btn btn-primary mb-3'}
                      >
                        <FaCheck />
                        <FormattedMessage id='all.candidateText' />
                      </Link>
                    )}
                  </div>
                )}
              {existCandidature && (
                <div className='mb-4'>
                  <Link
                    to={`/programas/${encodeURIComponent(
                      platformCode
                    )}/concursos/${encodeURIComponent(
                      competitionCode
                    )}/candidatura/${candidature.candidatureDto.externalId}`}
                    className={'btn btn-primary mb-3'}
                  >
                    <FaEye />
                    <FormattedMessage id='candidature.seeCandidatureText' />
                  </Link>
                </div>
              )}
              {!accessInfo && (
                <div className='mb-4'>
                  {competitionHabitations.habitations.length !== 0 &&
                    competitionHabitations.state ===
                      CANDIDATURES_SUBMISSION_PHASE && (
                      <a
                        href={nphCodeUrl}
                        type='button'
                        className={'btn btn-primary mb-3'}
                      >
                        <FaCheck />
                        <FormattedMessage id='all.candidateText' />
                      </a>
                    )}
                </div>
              )}


              {hasPermission('LIST_CANDIDATURES', accessInfo) &&
                ((isIhruUser(accessInfo) &&
                  showViewCandidaturesIHRUButton(
                    competitionHabitations.state
                  )) ||
                  (isCandidate(accessInfo) &&
                    showViewCandidaturesButton(
                      competitionHabitations.state
                    ))) && (
                  <Link
                    to={`/programas/${platformCode}/concursos/${competitionCode}/candidaturas`}
                    className={'btn btn-primary mb-3'}
                  >
                    <FaEye />
                    <FormattedMessage id='candidature.seeListOfCandidaturesText' />
                  </Link>
                )}
              <Link
                to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                  competitionHabitations.code
                )}/documentos?tipo=${DocumentTypeCode.NOTICE}`}
                className={'btn btn-outline-primary mb-3'}
              >
                <FaRegFileAlt />
                <FormattedMessage id='notice.button.text' />
              </Link>
              {competitionHabitations.state === DRAW && (
                <Link
                  to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                    competitionHabitations.code
                  )}/sorteio`}
                  className={'btn btn-primary mb-3'}
                >
                  <FaEye />
                  <FormattedMessage id='draw.button.text' />
                </Link>
              )}
              {competitionHabitations.state === CLOSED ||
                (competitionHabitations.state ===
                  DRAWN_CANDIDATURES_TREATMENT_PHASE &&
                  isIhruUser(accessInfo) &&
                  hasPermission('START_DRAW', accessInfo) && (
                    <Link
                      to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                        competitionHabitations.code
                      )}/sorteio`}
                      className={'btn btn-primary mb-3'}
                    >
                      <FaEye />
                      <FormattedMessage id='drawResultas.button.text' />
                    </Link>
                  ))}



              {allowActions.canShowLists && (
                <Link
                  to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                    competitionHabitations.code
                  )}/documentos?tipo=${DocumentTypeCode.LIST}`}
                  className={'btn btn-outline-primary mb-3'}
                >
                  <FaRegFileAlt />
                  <FormattedMessage id='list.button.text' />
                </Link>
              )}
            </div>

            <ul className={'nav flex-column border-left mx-2 my-3'}>
              {/* <li className={'nav-item'}>
                <a className={'nav-link'} href='/'>
                  <FaCheckCircle />
                  <FormattedMessage id='competitionHabitation.criteriasText' />
                </a>
              </li> */}
              <li className='nav-item'>
                <button
                  className={'nav-link btn btn-link'}
                  onClick={handleShow}
                >
                  <FaInfoCircle />
                  <FormattedMessage id='competitions.howItWorksText' />
                </button>
              </li>
              <li className={'nav-item'}>
                <Link to={`/perguntas/${platformCode}`} className={'nav-link'}>
                  <FaRegQuestionCircle />
                  <FormattedMessage id='competitionHabitation.faqsText' />
                </Link>
              </li>
            </ul>

          </div>



          <HowItWorksDialog
            handleClose={handleClose}
            show={show}
            platform={platform}
            showEditLink={hasPermission('UPDATE_PLATFORM', accessInfo)}
          />
          <DrawDialog
            handleCloseDrawModal={handleCloseDrawModal}
            showModalDraw={showModalDraw}
            handleStartDraw={handleStartDraw}
          />
          <YoutubeUrlDialog
              handleClose={handleCloseYoutubeUrl}
              show={showYoutubeUrl}
              handleConfirm={handleSetYoutubeUrl}
              url={competitionHabitations.youtubeUrl}
          />
        </div>
      </div>
    </SubTemplate>
  );
}

export default CompetitionHabitations;

function showViewCandidaturesButton(competitionState) {
  return (
    competitionState !== DRAFT &&
    competitionState !== APPROVED &&
    competitionState !== PUBLISHED &&
    competitionState !== CANDIDATURES_SUBMISSION_PHASE &&
    competitionState !== PRE_PUBLICATION_ACCEPTED_CANDIDATURES
  );
}
function showViewCandidaturesIHRUButton(competitionState) {
  return (
    competitionState !== DRAFT &&
    competitionState !== APPROVED &&
    competitionState !== PUBLISHED
  );
}
