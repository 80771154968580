import { Formik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import {TextInputField} from "../bootstrap/TextInputField";
import React from "react";

export function YoutubeUrlDialog({
  show,
  handleClose,
  handleConfirm,
    url
}) {
  const INITIAL_VALUES = {
    url: url,
  };
  const intl = useIntl();

  let schema = yup.object().shape({
    url: yup
      .string()
      .required(intl.formatMessage({ id: 'messages.emptyYoutubeUrl' })),
  });
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='competition.youtubeUrlDialog.title' />
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={schema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values, { setSubmitting }) => {
            handleConfirm(
              values.url,
              setSubmitting
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <TextInputField
                    name='url'
                    value={values.url}
                    handleChange={handleChange}
                    labelId='competition.youtubeUrlDialog.field'
                    errorMessage={errors.url}
                    isInvalid={errors.url && touched.url}
                />


              </Modal.Body>

              <Modal.Footer>
                <button
                  className='btn btn-link mr-auto'
                  onClick={handleClose}
                  type='button'
                >
                  <FormattedMessage id='all.cancelText' />
                </button>

                <button
                  className={'btn btn-primary ml-auto'}
                  type='submit'
                  disabled={isSubmitting}
                >
                  <FormattedMessage id='all.submitButtonText' />
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}
