import React, { useEffect, useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import {
    FaYoutube,
} from 'react-icons/fa';
import CountdownTimer from "react-component-countdown-timer";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { EmptyResults } from "../components/EmptyResults";
import Loading from "../components/Loading";
import { ScrollToTop } from "../components/ScrollToTop";
import { SubTemplate } from "../components/SubTemplate";
import {
  CLOSED,
  DRAW,
  DRAWN_CANDIDATURES_TREATMENT_PHASE,
} from "../models/CompetitionState";
import { getCompetitionHabitations } from "../rest/competition";
import { getDrawActualCandidatures } from "../rest/draw";
import { createDrawConnection } from "../websockets/ws";
import {useGlobalContext} from "../context";
import {isIhruUser} from "../authentication/authenticationHelper";

export function CompetitionDraw() {
  const { platformCode } = useParams();
  const { competitionCode } = useParams(); // Usar este competitionCode posteriormente no método createDrawConnection()

  const [drawnCandidatures, setDrawnCandidatures] = useState([]);

  /**
   * @type  {[W3CWebSocket, Function]} webSocketClient
   */
  const [webSocketClient, setWebSocketClient] = useState(null);
  const [competition, setCompetition] = useState(null);
  const [loading, setLoading] = useState(true);
  const { accessInfo } = useGlobalContext();

  const intl = useIntl();

  useEffect(() => {
    const competitionData = async () => {
      try {
        const { data: competition } = await getCompetitionHabitations(
          competitionCode,
          platformCode
        );
        setCompetition(competition);
        if (competition.state === DRAW) {
          initializeData();
        } else if (
          competition.state === CLOSED ||
          competition.state === DRAWN_CANDIDATURES_TREATMENT_PHASE
        ) {
          drawActualCandidaturesData();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    const drawActualCandidaturesData = async () => {
      try {
        const { data: candidatures } = await getDrawActualCandidatures(
          competitionCode,
          platformCode
        );
        setDrawnCandidatures(candidatures);
      } catch (error) {
        console.log(error);
      }
    };
    const initializeData = async () => {
      try {
        // const { data: ticket } = await getTicket(); vai ser um getCompetition

        if(isIhruUser(accessInfo)){
            let candidatures = [];

            const ws = createDrawConnection(competitionCode, platformCode);

            ws.onmessage = (message) => {
                const result = JSON.parse(message.data);

                candidatures = [...candidatures, ...result];

                setDrawnCandidatures(candidatures);
            };

            ws.onerror = (error) => {
                console.log(error);
            };
            ws.onclose = (message) => {};
            setWebSocketClient(ws);
        }


      } catch (error) {
        console.log(error);
      }
    };
    competitionData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <SubTemplate
      hasBackButton
      forceRedirect={`/programas/${platformCode}/concursos/${encodeURIComponent(
        competition.code
      )}`}
      title={`${intl.formatMessage({
        id: "draw.title.text",
      })} ${competition.code}`}
    >
      <div className={"container"}>
        {competition.state === CLOSED ||
          (competition.state === DRAWN_CANDIDATURES_TREATMENT_PHASE && (
            <ScrollToTop />
          ))}
        <div className={"row"}>
          <div className={"col-lg-12"}>
            {competition.state === CLOSED ||
              (competition.state === DRAWN_CANDIDATURES_TREATMENT_PHASE && (
                <p className="text-secondary mb-3">
                  <FormattedMessage id="draw.subTitleDateFinished.text" />
                  <strong>{competition.drawDate}</strong>
                </p>
              ))}
            {competition.state === DRAW && drawnCandidatures?.length > 1 && (
              <p className="text-secondary mb-3">
                <FormattedMessage id="draw.subTitleDate.text" />
              </p>
            )}
            {drawnCandidatures.length > 0 ? (
              <>
                {drawnCandidatures.length > 1 ||
                !drawnCandidatures[0].startDate ? (
                  <table className="table">
                    <DrawnCandidaturesTableHead platformCode={platformCode} />
                    <tbody>
                      {drawnCandidatures
                        .filter((c) => c.startDate == null)
                        .map((drawnCandidature, index) => (
                          <>
                            <DrawnCandidatureRow
                              key={index}
                              drawnCandidature={drawnCandidature}
                              competition={competition}
                              platformCode={platformCode}
                            />
                            {drawnCandidature.last === true &&
                              webSocketClient.close()}
                          </>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <>
                    <p>
                      {" "}
                      <FormattedMessage id="draw.init.text" />
                    </p>
                    <CountdownTimer
                      count={
                        (new Date(drawnCandidatures[0].startDate).getTime() -
                          new Date().getTime()) /
                        1000
                      }
                    />
                  </>
                )}
              </>
            ) : (
                <>
                {competition.state === DRAW && !isIhruUser(accessInfo) ? (
                    <p className="text-secondary mb-3">
                        <FormattedMessage id="draw.url.text" />

                        <Link
                            to={ {pathname:competition.youtubeUrl}}
                            target={competition.youtubeUrl}

                        >
                            &nbsp;<FaYoutube />
                            {competition.youtubeUrl}
                        </Link>
                    </p>
                    ): <EmptyResults />}

                </>


            )}
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

function DrawnCandidatureRow({ drawnCandidature, competition, platformCode }) {
  const ref = useRef(null);

  useEffect(() => {
    if (competition.state === DRAW) {
      ref.current.scrollIntoView();
    }
  }, []);

  return (
    <>
      <tr ref={ref}>
        <>
          {platformCode === "PAA" || platformCode === "PAS" ? (
            <>
              <td>{drawnCandidature.position}</td>
              <td>{drawnCandidature.code}</td>
              <td>
                {createHousingAllocationDescription(
                  drawnCandidature.habitationCode,
                  drawnCandidature.habitationAddress,
                  drawnCandidature.rent,
                    platformCode
                )}
              </td>
            </>
          ) : (
            <>
              <td>{drawnCandidature.position}</td>
              <td>{drawnCandidature.code}</td>
              <td>
                {drawnCandidature.code} - {drawnCandidature.competitionCode}
              </td>
              <td>
                {getDrawnedHabitationTypologyMessage(
                  drawnCandidature.drawnHabitationTypology
                )}
              </td>
              <td>
                {getDrawnedHabitationMessage(drawnCandidature.habitationCode)}
              </td>
            </>
          )}
        </>
      </tr>
      {drawnCandidature.last === true && competition.state === DRAW && (
        <tr>
          <td colSpan="3">
            <Alert
              className="py-4"
              key={drawnCandidature.position}
              variant={"danger"}
            >
              <div className="text-center mb-2">
                <strong>
                  <FormattedMessage id="draw.finish.text" />
                </strong>
              </div>
              <div className="text-center">
                <Link
                  className="mx-2"
                  to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                    competition.code
                  )}/documentos`}
                >
                  <u>
                    <FormattedMessage id="drawResults.lists.button.text" />
                  </u>
                </Link>
              </div>
            </Alert>
          </td>
        </tr>
      )}
    </>
  );
}

function DrawnCandidaturesTableHead({ platformCode }) {
  return (
    <>
      <thead>
        {platformCode === "PAA" || platformCode === "PAS" ? (
          <tr>
            <th className="w-15">
              <label>
                <FormattedMessage id="kafka.drawnCandidatures.orderNumber" />
              </label>
            </th>
            <th className="w-35">
              <label>
                <FormattedMessage id="kafka.drawnCandidatures.candidatureCertificateNumber" />
              </label>
            </th>
            <th className="w-50">
              <label>
                <FormattedMessage id="kafka.drawnCandidatures.housingAllocation" />
              </label>
            </th>
          </tr>
        ) : (
          <tr>
            <th className="w-15">
              <label>
                <FormattedMessage id="kafka.drawnCandidatures.orderNumber" />
              </label>
            </th>
            <th className="w-35">
              <label>
                <FormattedMessage id="kafka.drawnCandidatures.candidatureNumber" />
              </label>
            </th>
            <th className="w-15">
              <label>
                <FormattedMessage id="kafka.drawnCandidatures.housingSupportApplicationNumber" />
              </label>
            </th>
            <th className="w-15">
              <label>
                <FormattedMessage id="kafka.drawnCandidatures.drawnHabitationTypology" />
              </label>
            </th>
            <th className="w-15">
              <label>
                <FormattedMessage id="kafka.drawnCandidatures.drawnHabitation" />
              </label>
            </th>
          </tr>
        )}
      </thead>
    </>
  );
}

function createHousingAllocationDescription(
  habitationCode,
  habitationAddress,
  rent,
  platformCode
) {
  if (!habitationCode) {
    return (
      <FormattedMessage id="kafka.drawnCandidatures.noHousingAllocation.description" />
    );
  } else if(platformCode!=='PAS'){
    return (
      <FormattedMessage
        id="kafka.drawnCandidatures.housingAllocation.description"
        values={{
          habitationCode: habitationCode,
          streetName: habitationAddress.streetName,
          location: habitationAddress.location,
          lot: habitationAddress.lot,
          floor: habitationAddress.floor,
          postalCode: habitationAddress.postalCode,
          countyName: habitationAddress.countyName,
          parishName: habitationAddress.parishName,
          rent: rent,
        }}
      />
    );
  }
  return (
      <FormattedMessage
          id="kafka.drawnCandidatures.housingAllocationPAS.description"
          values={{
            habitationCode: habitationCode,
            streetName: habitationAddress.streetName,
            location: habitationAddress.location,
            lot: habitationAddress.lot,
            floor: habitationAddress.floor,
            postalCode: habitationAddress.postalCode,
            countyName: habitationAddress.countyName,
            parishName: habitationAddress.parishName,
          }}
      />
  );
}

function getDrawnedHabitationMessage(habitationCode) {
  return habitationCode ? (
    <FormattedMessage
      id="kafka.drawnCandidatures.drawnedHabitation"
      values={{
        habitationCode: habitationCode,
      }}
    />
  ) : (
    <FormattedMessage id="all.noText" />
  );
}

function getDrawnedHabitationTypologyMessage(drawnedHabitationTypology) {
  return drawnedHabitationTypology ? (
    drawnedHabitationTypology
  ) : (
    <FormattedMessage id="kafka.drawnCandidatures.notApplicableMessage" />
  );
}
