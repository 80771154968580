export const DRAFT = 'DRAFT';
export const APPROVED = 'APPROVED';
export const PUBLISHED = 'PUBLISHED';
export const CANDIDATURES_SUBMISSION_PHASE = 'CANDIDATURES_SUBMISSION_PHASE';
export const PRE_PUBLICATION_ACCEPTED_CANDIDATURES =
  'PRE_PUBLICATION_ACCEPTED_CANDIDATURES';
export const PUBLICATION_ACCEPTED_CANDIDATURES =
  'PUBLICATION_ACCEPTED_CANDIDATURES';
export const DRAW = 'DRAW';
export const DRAWN_CANDIDATURES_TREATMENT_PHASE =
  'DRAWN_CANDIDATURES_TREATMENT_PHASE';
export const CLOSED = 'CLOSED';
export const SUSPENDED = 'SUSPENDED';
export const CONTESTED = 'CONTESTED';
export const DELETED = 'DELETED';

export const CompetitionState = {
  DRAFT: 'DRAFT',
  APPROVED: 'APPROVED',
  PUBLISHED: 'PUBLISHED',
  CANDIDATURES_SUBMISSION_PHASE: 'CANDIDATURES_SUBMISSION_PHASE',
  PRE_PUBLICATION_ACCEPTED_CANDIDATURES:
    'PRE_PUBLICATION_ACCEPTED_CANDIDATURES',
  PUBLICATION_ACCEPTED_CANDIDATURES: 'PUBLICATION_ACCEPTED_CANDIDATURES',
  DRAW: 'DRAW',
  DRAWN_CANDIDATURES_TREATMENT_PHASE: 'DRAWN_CANDIDATURES_TREATMENT_PHASE',
  CLOSED: 'CLOSED',
  SUSPENDED: 'SUSPENDED',
  CONTESTED: 'CONTESTED',
  DELETED: 'DELETED',

  getActives: () => {
    return [
      'PUBLISHED',
      'CANDIDATURES_SUBMISSION_PHASE',
      'PRE_PUBLICATION_ACCEPTED_CANDIDATURES',
      'PUBLICATION_ACCEPTED_CANDIDATURES',
      'DRAW',
      'DRAWN_CANDIDATURES_TREATMENT_PHASE',
    ];
  },

  getBeforeAdmission: () => {
    return [
      'DRAFT',
      'APPROVED',
      'PUBLISHED',
      'CANDIDATURES_SUBMISSION_PHASE',
      'PRE_PUBLICATION_ACCEPTED_CANDIDATURES',
    ];
  }


};
